import React from 'react';
import {
  ChasingDots,
  Circle,
  CubeGrid,
  DoubleBounce,
  FadingCircle,
  FoldingCube,
  Pulse,
  RotatingPlane,
  ThreeBounce,
  WanderingCubes,
  Wave } from 'better-react-spinkit';

import './styles.scss';

const mainLoader = (
  <div className="spinner-outer" style={{ height: 'auto', marginTop: 200 }}>
    {/* <Circle
      scaleStart={0.4}
      scaleEnd={0.7}
      size={120}
      color="#4A4A4A"
    /> */}

    <Wave size={130} color='#797DF9' />
  </div>
);

export default mainLoader;
