import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import registerFCMToken from './api/registerFCMToken';
import {getToken as getUserToken} from "./helpers/token"
const firebaseConfig = {
  apiKey: "AIzaSyBOVQgIMD2ORbQ-nYJ91kivV6Ej6Xqh5Dc",
  authDomain: "notification-payqin.firebaseapp.com",
  databaseURL: "https://notification-payqin.firebaseio.com",
  projectId: "notification-payqin",
  storageBucket: "notification-payqin.appspot.com",
  messagingSenderId: "485034843664",
  appId: "1:485034843664:web:a2acddaf33fecc56a5b495",
  measurementId: "G-HS7V78MJS1"
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const messaging = getMessaging()

export const requestForToken = async () => {
  try {
    if(!getUserToken()){ // must login to proceed
      return  ;
    }
    const currentToken = await getToken(messaging,
      { vapidKey: "BK9BmmBekNMrPpl58_tRQ7U7MvMPBoKsAv7Q_fA9sGuUfk_xFwDsA2ZjPez7Hui8ySdHH7c3F5y5pjds053wimU" });
    if (currentToken) {
      console.log("[firebase] current token", currentToken);
      registerFCMToken({lastFCMToken:currentToken})
        .then(() =>{
          console.log("[firebase] successfully registered token :" + currentToken)
        })
        .catch(console.error)
    } else {
      console.log('[firebase] No registration token available. Request permission to generate one');
    }
  } catch (err) {
    return console.log('[firebase] An error occured while retrieving token', err);
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("[firebase] got message" , payload)
      resolve(payload)
    })
  } )
