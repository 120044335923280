import host from './host'
import I18n from 'i18n-js'
import { getToken } from '../helpers/token'
import appVersion from './appVersion'

export default async function getUser ()
{
    try {

     const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
            'Accept-Language': I18n.currentLocale(),
            'mode' : 'no-cors',
            'version': appVersion
        }
    };

        const response = await fetch(`${host}/users/me`, requestOptions)


        const alternativeResp = { error: true, status: 401 }

        if(response.status === 401) {
            return alternativeResp
        }

        return response.json()
    }
    catch (error) {
        return console.log(error)
    }
}
