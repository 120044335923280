import changeState from './changeState'
import toggleUserData from './toggleUserData'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
  changeState,
  toggleUserData
});

export default allReducers
