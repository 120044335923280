const initialState = { user: {} }

function toggleUserData(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'TOGGLE_USER_DATA':
      if (state.user.id === action.value.id) {
        nextState = {
          ...state,
          user: {}
        }
      }
      else {
        nextState = {
          ...state,
          user: action.value
        }
      }
      return nextState || state
  default:
    return state
  }
}

export default toggleUserData
