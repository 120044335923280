import host from './host';
import i18n from 'i18n-js'
import {getToken} from 'src/helpers/token'

export default async function registerFCMToken({lastFCMToken})
{

  const requestOptions = {
    method : 'POST' ,
    headers : {
      'Accept-Language' : i18n.currentLocale(),
      'Authorization' : `Bearer ${getToken()}`,
      'Content-type' : 'application/json'
    },
    body : JSON.stringify({
      lastFCMToken
    })
  }

  const response = await fetch(`${host}/retailer/register-fcm-token` , requestOptions)
  return response.json()
}
