import React, { Component } from 'react';
import { HashRouter, Route, Switch, Router } from 'react-router-dom';
import './scss/style.scss';
import './assets/css/custom.css';
import './assets/css/media.css';
// import './assets/css/theme.min.css';
import mainLoader from './views/base/mainLoader'
import { useHistory } from 'react-router-dom'
import { AuthContext } from './context/auth'
import { getToken } from './helpers/token'
import { UserContext } from './context/userData'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import PropTypes from 'prop-types'
import getUser from './api/getUser'
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const VerifyOtpCode = React.lazy(() => import('./views/pages/verifyOtpCode/VerifyOtpCode'));

const App = ({
  user,
  setUser,
  gettingUserLoading,
  setUserLoading,
  getUser
}) => {

    return (
      <UserContext.Provider value={user}>
        <HashRouter>
            <React.Suspense fallback={mainLoader}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login  {...props}/>} />{/**router={useHistory()} */}
                {/* <Route exact path="/login/verify" name="Verify Otp Code" render={props => <VerifyOtpCode {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </UserContext.Provider>
    );
}

App.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  gettingUserLoading: PropTypes.bool.isRequired,
  setUserLoading: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired
 }

 export default compose(
   withState('user', 'setUser', {}),
   withState('gettingUserLoading', 'setUserLoading', true),
   withHandlers({
     getUser: ({
       setUser,
       setUserLoading
     }) => async () => {
       const res = await getUser()
       if(res.success) {
         setUser(res.user)
         setUserLoading(false)
       } else {
         setUser('none')
         setUserLoading(false)
       }
   }

   }),
   lifecycle({
     componentDidMount() {
       this.props.getUser()
     }
   })

 )(App)
